  // 详情弹出框
<template>
     <base-dialog
      :visible.sync="visibleState"
      :showFooter="false"
      title="项目合同详情"
    >
    <titleTemplate :name="name"/>
    <formInfo :count="count" :componentList='formInfoConfig' :model="detailInfo"/>
      <template slot="footer" >
     <base-button label="关闭" @click="visibleState = false" type="default"/>
    </template>
    </base-dialog>
</template>
<script>
import titleTemplate from '@/pages/business/components/titleTemplate.vue'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import FormInfo from '@/components/packages/form-info/form-info.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { formInfoConfig } from '../utils/contract-details-config'
import { assetcontractApi } from '@/api/assetApi'
import { formatDate } from '@/utils/auth/common'
import { getDict } from '@/filters/fromDict'

export default {
  props: {
    visible: Boolean,
    name: String,
    keyId: String,
    contractId: String,
    type: {
      type: String,
      default: 'default'
    }
  },
  data () {
    return {
      count: 0,
      detailInfo: {},
      showInput: true
    }
  },
  components: { titleTemplate, BaseDialog, FormInfo, BaseButton },
  computed: {
    formInfoConfig () {
      return formInfoConfig(this)
    },
    visibleState: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.detail()
      }
    }
  },
  methods: {
    // 详情表格数据
    detail () {
      this.detailInfo = {}
      // let params = {}
      // if (this.type === 'default') {
      //   params = { keyId: this.keyId }
      // } else {
      //   params = { contractId: this.keyId }
      // }
      const params = { contractId: this.contractId, keyId: this.keyId }
      assetcontractApi.contractDetail(params).then(res => {
        this.detailInfo = { ...res.data }
        res.data.paymentTotalPercent = res.data.paymentTotalPercent ? res.data.paymentTotalPercent : ''
        res.data.effectiveSignAmount = res.data.effectiveSignAmount ? res.data.effectiveSignAmount : ''
        res.data.supplementContractAmount = res.data.supplementContractAmount ? res.data.supplementContractAmount : ''
        res.data.settlementPercent = res.data.settlementPercent ? res.data.settlementPercent : ''
        res.data.paymentPercent = res.data.paymentPercent ? res.data.paymentPercent : ''
        res.data.outputTotalAmountStr = res.data.outputTotalAmountStr ? res.data.outputTotalAmountStr : '0.00'
        res.data.signTime = res.data.signTime ? formatDate(res.data.signTime, 'YY/MM/DD') : ''
        res.data.settlementFlag = res.data.settlementFlag !== undefined ? getDict('SETTLEMENT_TYPE', String(res.data.settlementFlag)) : ''
        res.data.settmentTime = res.data.settmentTime ? formatDate(res.data.settmentTime, 'YY/MM/DD') : ''
        res.data.settmentPaymentTime = res.data.settmentPaymentTime ? formatDate(res.data.settmentPaymentTime, 'YY/MM/DD') : ''
        res.data.latestPayTime = res.data.latestPayTime ? formatDate(res.data.latestPayTime, 'YY/MM/DD') : ''
        this.detailInfo = { ...res.data }
        res.data.contractType === '工程类' ? this.showInput = false : this.showInput = true

        this.count++
      })
    }
  }
}
</script>

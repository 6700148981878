<template>
  <div>
    <span class="jump" @click="jump($attrs.type)">{{$attrs.value}}</span>
    <!-- 合同弹出框  -->
    <contract-details
      :visible.sync="visibleDetail"
      :name="name"
      :contractId="contractId"
      :keyId="keyId"
    />
    <!-- 付款单详情信息 -->
    <payment-order-details-dialog :visible.sync="visibleOrderDetails" v-if="visibleOrderDetails" :keyId="keyId"></payment-order-details-dialog>
    <!-- 融资申请详情 -->
    <access-documents-make-dialog :visible.sync="visblebusinesDetails" v-if="visblebusinesDetails" :businessId="businessId"></access-documents-make-dialog>
  </div>
</template>
<script>
import ContractDetails from '@/pages/assets-manage/project-information/components/contract-details.vue'
import PaymentOrderDetailsDialog from '@/pages/assets-manage/payment-order-details/components/payment-order-details-dialog.vue'
import AccessDocumentsMakeDialog from '@/pages/business/financing-application-management/components/access-documents-make-dialog.vue'
import Storage from '@/utils/storage'
export default {
  props: {
    data: Object
  },
  data () {
    return {
      lookVisible: false,
      visibleDetail: false,
      name: '',
      contractId: '',
      keyId: '',
      visibleOrderDetails: false,
      visblebusinesDetails: false,
      businessId: ''
    }
  },
  components: {
    ContractDetails,
    PaymentOrderDetailsDialog,
    AccessDocumentsMakeDialog
  },
  methods: {
    jump (type) {
      if (type === '1') {
        this.paymentOrderDetail(this.data)
      } else if (type === '2') {
        this.businessDetail(this.data)
      } else if (type === '3') {
        this.contractDetail(this.data)
      }
    },
    // 查看项目合同信息详情
    contractDetail (row) {
      this.name = '合同名称：' + row.contractName
      this.keyId = row.keyId
      this.contractId = row.contractId
      this.visibleDetail = true
    },
    // 查看付款单信息
    paymentOrderDetail (row) {
      this.keyId = row.keyId
      this.visibleOrderDetails = true
    },
    // 融资详情
    businessDetail (row) {
      this.businessId = row.keyId
      Storage.setLocal('admittanceInfo', row)
      this.visblebusinesDetails = true
    }
  }
}
</script>
<style lang="scss" scoped>
.jump {
  color:#2862e7;
  cursor: pointer;
}
</style>>

// 查看详情
export const formInfoConfig = (content) => {
  const span = 12
  return [
    {
      label: '供应商名称',
      prop: 'gysName',
      span
    },
    {
      label: '项目公司',
      prop: 'xmgsName',
      span
    },
    {
      label: '项目公司简码',
      prop: 'xmgsShortCode',
      span
    },
    {
      label: '区域',
      prop: 'area',
      span
    },
    {
      label: '项目编号',
      prop: 'projectCode',
      span

    },
    {
      label: '项目名称',
      prop: 'projectName',
      span

    },
    {
      label: '所属城市公司',
      prop: 'city',
      span

    },
    {
      label: '合同编号',
      prop: 'contractCode',
      span
    },
    {
      label: '合同类别',
      prop: 'contractType',
      span
    }, {
      label: '合同签署时间',
      prop: 'signTime',
      span
    },
    {
      label: '累计付款比例(%)',
      prop: 'paymentTotalPercent',
      span
    },
    {
      label: '合同金额(元)',
      prop: 'contractAmount',
      span

    },
    {
      label: '合同有效签约金额(元)',
      prop: 'effectiveSignAmount',
      span

    },
    {
      label: '补充协议调整金额(元)',
      prop: 'supplementContractAmount',
      span

    },
    {
      label: '已付金额(元)',
      prop: 'paidAmount',
      span

    },
    {
      label: '进度款占比(%)',
      prop: 'paymentPercent',
      // isHidden: content.showInput,
      span
    },
    {
      label: '结算款占比(%)',
      prop: 'settlementPercent',
      // isHidden: content.showInput,
      span
    },
    {
      label: '完成工程产值(元)',
      prop: 'outputTotalAmountStr',
      // isHidden: content.showInput,
      span
    },
    {
      label: '是否已结算',
      prop: 'settlementFlag',
      span
    },
    {
      label: '结算时间',
      prop: 'settmentTime',
      span
    },
    {
      label: '结算款支付时间',
      prop: 'settmentPaymentTime',
      span
    },
    {
      label: '支付方式',
      prop: 'payType',
      span
    },
    {
      label: '最后申请付款时间',
      prop: 'latestPayTime',
      span
    },
    {
      label: '备注',
      span: 24,
      prop: 'memo',
      showAll: true
    }
  ]
}
